@import "./src/designSystem/colors.scss";

.general-entrance-wrapper{
    padding-right: 30px;
    height: 100%;
}

@media only screen and (max-width: 767px) {
    .general-entrance-wrapper{
        padding: 0 20px;
    }
}
